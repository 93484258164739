<template>
  <div class="position-relative">
    <div
      class="d-flex"
      :class="[direction === 'row' ? 'flex-row' : 'flex-column']"
    >
      <div
        v-if="direction === 'row'"
        class="col-8"
      >
        <textarea
          :id="id"
          v-model="inputValue"
          autocomplete="autocomplete"
          class="form-control"
          style="border-radius: 0px"
          :class="[$style[`input`], { 'border-danger': errMessage !== '' }]"
          :maxlength="maxlength"
          :placeholder="placeholder"
          @input="updateInput($event)"
          @focus="$emit('focus')"
          @keydown.enter="$emit('enter')"
        />
      </div>
      <textarea
        v-if="direction !== 'row'"
        :id="id"
        v-model="inputValue"
        autocomplete="autocomplete"
        class="form-control"
        :class="[$style[`input`], { 'border-danger': errMessage !== '' }]"
        :maxlength="maxlength"
        :placeholder="placeholder"
        style="border-radius: 4px"
        @input="updateInput($event)"
        @focus="$emit('focus')"
        @keydown.enter="$emit('enter')"
      />
      <div
        v-if="label !== ''"
        class="d-flex align-items-center order-first"
        :class="[
          [$style['label']],
          [
            direction === 'row'
              ? 'col-4 text-dark justify-content-end pe-2'
              : `position-absolute top-0 small ${[$style[`label-column`]]}`
          ]
        ]"
      >
        <span
          :for="id"
        >{{ label }}
          <span
            v-if="required"
            :class="$style.required"
          >*</span>：</span>
      </div>
    </div>
    <small
      class="text-danger position-absolute end-0"
      :class="[$style[`error-text`], { [$style[`show`]]: errMessage !== '' }]"
    >{{ errMessage }}</small>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'

export default {
  name: 'Textarea',
  props: {
    input: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'text'
    },
    errMessage: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    maxlength: {
      type: Number,
      default: 100
    },
    direction: {
      type: String,
      default: 'column'
    }
  },
  emits: ['focus', 'enter', 'update:input'],
  setup (props, { emit }) {
    const inputValue = ref(props.input)
    const setInputValue = (value) => {
      inputValue.value = value
    }
    const updateInput = (event) => {
      emit('update:input', event.target.value)
    }

    watch(
      () => props.input,
      () => {
        if (props.input !== inputValue.value) {
          setInputValue(props.input)
        }
      }
    )

    return {
      inputValue,
      updateInput
    }
  }
}
</script>

<style lang="scss" module>
.label {
  color: $gray-600;
  transition: opacity 0.3s;
}

.label-column {
  opacity: 0;
  transform: translateY(-80%);
}

.input {
  resize: none;
  height: 200px;
}

.input:focus ~ .label {
  opacity: 1;
}

.required {
  color: $red-200;
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}
</style>
