<template>
  <div>
    <div class="mx-lg-5 px-3 px-md-4 py-4 shadow rounded-3">
      <h4
        class="col text-md-start mt-5 mb-4"
        style="padding: 25px"
      >
        確認付款與收件資訊
      </h4>
      <div
        class="row col-12 align-items-center mx-auto mb-5 mb-md-0 px-0 px-md-2 py-2"
      >
        <div
          v-for="field in fieldList"
          :key="field"
        >
          <!-- paywayID -->
          <div
            v-if="field === 'paywayID'"
            class="position-relative mb-4"
          >
            <div class="d-flex flex-column">
              <select
                :id="field"
                v-model="state[field]"
                class="form-select"
                :class="[
                  $style[`input`],
                  { 'border-danger': v$[field]?.$error }
                ]"
                :placeholder="labelMap[field].placeholder"
                @focus="v$[field].$touch"
              >
                <option
                  value="null"
                  disabled="disabled"
                >
                  請選擇付款方式
                </option>
                <option
                  v-for="item in payways"
                  :key="item.paywayID"
                  :value="item.paywayID"
                >
                  {{ item.payway }}
                </option>
              </select>
              <div
                class="d-flex align-items-center order-first position-absolute top-0 small"
                :class="[[$style['label']], [$style[`label-column`]]]"
              >
                <span
                  :for="field"
                >{{ labelMap[field].label }}
                  <span :class="$style.required">*</span>：</span>
              </div>
            </div>
            <small
              class="text-danger position-absolute end-0"
              :class="[
                $style[`error-text`],
                { [$style[`show`]]: v$[field]?.$errors[0]?.$message !== '' }
              ]"
            >{{ v$[field]?.$errors[0]?.$message || '' }}</small>
          </div>
          <!-- serviceID -->
          <div
            v-else-if="field === 'serviceID'"
            class="position-relative mb-4"
          >
            <div class="d-flex flex-column">
              <select
                :id="field"
                v-model="state[field]"
                class="form-select"
                :class="[
                  $style[`input`],
                  { 'border-danger': v$[field]?.$error }
                ]"
                :placeholder="labelMap[field].placeholder"
                @focus="v$[field].$touch"
              >
                <option
                  value="null"
                  disabled="disabled"
                >
                  請選擇配送服務
                </option>
                <option
                  v-for="item in shipServices"
                  :key="item.serviceID"
                  :value="item.serviceID"
                >
                  {{ item.server }}
                </option>
              </select>
              <div
                class="d-flex align-items-center order-first position-absolute top-0 small"
                :class="[[$style['label']], [$style[`label-column`]]]"
              >
                <span
                  :for="field"
                >{{ labelMap[field].label }}
                  <span :class="$style.required">*</span>：</span>
              </div>
            </div>
            <small
              class="text-danger position-absolute end-0"
              :class="[
                $style[`error-text`],
                { [$style[`show`]]: v$[field]?.$errors[0]?.$message !== '' }
              ]"
            >{{ v$[field]?.$errors[0]?.$message || '' }}</small>
          </div>
          <Textarea
            v-else-if="field === 'memo'"
            :id="field"
            v-model:input="state[field]"
            class="mb-4"
            :required="rules?.requiredHelper"
            :autocomplete="'on'"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
          />
          <label
            v-else-if="field === 'saveRecipient'"
            class="d-flex"
          >
            <input
              :id="field"
              v-model="state[field]"
              class="form-check-input me-2"
              type="checkbox"
            >{{ labelMap[field].label }}</label>
          <Input
            v-else
            :id="field"
            v-model:input="state[field]"
            class="mb-4"
            :type="labelMap[field].label"
            :required="rules?.requiredHelper"
            :autocomplete="'on'"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, reactive, computed, watch, unref } from 'vue'
import Input from '@/components/Input.vue'
import Textarea from '@/components/Textarea.vue'
import useVuelidate from '@vuelidate/core'
import {
  requiredHelper,
  mobileHelper,
  emailHelper
} from '@/hooks/useVuelidate.js'
import { useStore } from 'vuex'

export default {
  name: 'CheckoutShipperDetail',
  components: {
    Input,
    Textarea
  },
  setup () {
    const { getters } = useStore()

    const payways = computed(() => getters['payment/getPayways'])
    const shipServices = computed(() => getters['order/getShipServices'])
    const recipientRecord = computed(() => getters['member/getRecipientRecord'])
    const alert = computed(() => getters.getAlert)

    const setv$ = inject('setv$')
    const setSelectedPayway = inject('setSelectedPayway')
    const setSelectedShipService = inject('setSelectedShipService')
    const setAlert = inject('setAlert')

    const fieldList = [
      'serviceID',
      'paywayID',
      'recipient',
      'cell',
      'email',
      'shipAddr',
      'memo',
      'saveRecipient'
    ]

    const labelMap = {
      serviceID: {
        type: 'text',
        label: '配送方式',
        placeholder: '請選擇配送服務'
      },
      paywayID: {
        type: 'text',
        label: '付款方式',
        placeholder: '請選擇付款方式'
      },
      recipient: {
        type: 'text',
        label: '收件姓名',
        placeholder: '請輸入收件者姓名'
      },
      cell: {
        type: 'text',
        label: '收件手機',
        placeholder: '請輸入收件者聯絡手機'
      },
      email: {
        type: 'email',
        label: 'Email',
        placeholder: '請輸入訂單通知email'
      },
      shipAddr: {
        type: 'text',
        label: '收件地址',
        placeholder: '請輸入收件或取件地址'
      },
      memo: {
        type: 'text',
        label: '訂單備註',
        placeholder: '有什麼話想跟娜娜說'
      },
      saveRecipient: {
        label: '記住收件資訊'
      }
    }

    const state = reactive({
      serviceID: null,
      paywayID: null,
      recipient: recipientRecord.value?.rcptName || '',
      cell: recipientRecord.value?.rcptCell || '',
      email: recipientRecord.value?.rcptMail || '',
      shipAddr: recipientRecord.value?.rcptAddress || '',
      memo: null,
      saveRecipient: true
    })
    if (
      unref(recipientRecord) &&
      Object.keys(unref(recipientRecord)).length > 0 &&
      !alert.value.show
    ) {
      setAlert(true, true, '已為您帶入儲存的寄件資訊')
    }

    const rules = {
      serviceID: { requiredHelper },
      paywayID: { requiredHelper },
      recipient: { requiredHelper },
      cell: { requiredHelper, mobileHelper },
      email: { requiredHelper, emailHelper },
      shipAddr: { requiredHelper },
      memo: {},
      saveRecipient: {}
    }

    watch(
      () => state.paywayID,
      () => {
        setSelectedPayway(
          payways.value.find((item) => item.paywayID === state.paywayID) || {}
        )
      }
    )

    watch(
      () => state.serviceID,
      () => {
        setSelectedShipService(
          shipServices.value.find(
            (item) => item.serviceID === state.serviceID
          ) || {}
        )
      }
    )

    const v$ = useVuelidate(rules, state)
    setv$(v$)

    return {
      v$,
      fieldList,
      labelMap,
      shipServices,
      state,
      payways,
      rules
    }
  }
}
</script>
<style lang="scss" module>
.label {
  color: $gray-600;
  transition: opacity 0.3s;
}

.label-column {
  opacity: 0;
  transform: translateY(-80%);
}

.input:focus ~ .label {
  opacity: 1;
}

.required {
  color: $red-200;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}
</style>
