<template>
  <ul class="small list-unstyled d-flex flex-row justify-content-center px-3 py-1 mb-3 rounded-3">
    <li>
      <router-link
        to="/"
        class="text-decoration-none"
        :class="$style.textGray600"
      >
        首頁
      </router-link>
    </li>
    <li
      v-for="link in breadCrumbs"
      :key="link"
    >
      <span
        class="mx-2"
        :class="$style.textGray600"
      >/</span>
      <router-link
        :to="link?.to || ''"
        class="text-decoration-none"
        :class="[link?.to ? $style.textGray600 : 'text-dark']"
      >
        {{ link.title }}
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    breadCrumbs: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" module>
.textGray600 {
  color: $gray-600;
}
</style>
